<template>
  <div class="container-fluid">
    <Alert :message="success" type="alert alert-success" v-if="success" />
    <Alert :message="error" type="alert alert-danger" v-if="error" />

    <!--Delete Modal start -->
    <div
      class="modal fade"
      id="deleteModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Delete License Form
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to do this ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              @click="deleteLicense"
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--Delete Modal end -->

    <!-- new license modal open -->
    <div
      class="modal fade"
      id="newlicense"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Upload License Form
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="file">File</label>
              <input
                type="file"
                id="file"
                accept="application/pdf,application/vnd.ms-excel"
                ref="file"
                @change="contentFile"
                class="form-control"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              @click="uploadLicense"
              type="submit"
              class="btn btn-success"
              data-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- new license modal close -->

    <!-- edit license modal open -->
    <div
      class="modal fade"
      id="editlicense"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Update License Form
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="file">File</label>
              <input
                type="file"
                id="file"
                accept="application/pdf,application/vnd.ms-excel"
                ref="file"
                @change="contentFile"
                class="form-control"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              @click="updateLicense"
              type="submit"
              class="btn btn-success"
              data-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- edit license modal close -->

    <div class="row d-flex justify-content-between">
      <div></div>
      <button
        v-if="licenseForms.length < 1"
        @click="getKeyAndId(categoryId)"
        data-toggle="modal"
        data-target="#newlicense"
        class="btn m-3 p-1 btn-primary"
      >
        Upload License Form
      </button>
    </div>
    <div class="row">
      <div class="col">
        <div class="card border-left-danger">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-6">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select
                              v-model="perPage"
                              size="sm"
                              :options="pageOptions"
                            ></b-form-select
                            >&nbsp;entries
                          </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-6">
                        <div
                          id="tickets-table_filter"
                          class="dataTables_filter text-md-right"
                        >
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filter"
                              type="search"
                              placeholder="Search..."
                              class="form-control form-control-sm ml-2"
                            ></b-form-input>
                          </label>
                        </div>
                      </div>
                      <!-- End search -->
                    </div>
                    <div class="table-responsive">
                      <b-table
                        :items="licenseForms"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sorrt-desc.sync="sortDesc"
                        :fit-by.sync="sortBy"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        hover
                      >
                        <template #cell(file)="data">
                          <div
                            @click="getFile(data.item.file)"
                            style="cursor: pointer"
                          >
                            <i class="fa fa-file" aria-hidden="true"> </i>
                          </div>
                        </template>
                        <template v-slot:cell(action)="row">
                          <div class="dropdown">
                            <a
                              href="#"
                              data-toggle="dropdown"
                              data-caret="false"
                              class="text-muted"
                              ><b-icon-three-dots></b-icon-three-dots
                            ></a>
                            <div class="dropdown-menu dropdown-menu-right p-3">
                              <button
                                @click="getId(row.item.id, row.item.name)"
                                data-toggle="modal"
                                data-target="#editlicense"
                                class="btn m-0 px-4"
                              >
                                Edit License Form
                              </button>
                              <button
                                @click="getKeyAndId(row.item.id)"
                                data-toggle="modal"
                                data-target="#deleteModal"
                                class="btn m-0 px-4 text-danger"
                              >
                                Delete License Form
                              </button>
                            </div>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Alert";
import store from "@/store";
import pdf from "vue-pdf";
import url from "@/helpers/url";

export default {
  components: {
    Alert: Alert,
    pdf,
  },
  data() {
    return {
      //  data table
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "license_category.name", sortable: true, label: "Name" },
        { key: "file" },
        { key: "Action" },
      ],

      //   table data
      categoryId: this.$route.params.categoryId,
      licenseForms: [],
      url: `https://staging.afcfta.app`,
      id: null,
      key: null,
      success: null,
      error: null,
      name: "",
      file: {},
    };
  },
  methods: {
    getKeyAndId(id) {
      this.id = id;
    },
    getFile(file) {
      console.log(file);
      this.$router.push({
        name: "form-pdf",
        params: { file: file },
      });
    },
    getId(id, name) {
      this.id = id;
      this.name = name;
    },
    toPdf(id, file) {},
    contentFile(e) {
      console.log(e);
      const selectedFile = e.target.files[0];
      this.file = selectedFile;
    },
    uploadLicense() {
      let form_data = new FormData();
      form_data.append("license_category", this.id);
      form_data.append("file", this.file);

      store
        .dispatch("addLicenseForm", form_data)
        .then((response) => {
          console.log(response.data);
          this.id = "";
          this.file = "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateLicense() {
      let form_data = new FormData();

      form_data.append("license_category", this.id);
      form_data.append("file", this.file);

      const license = {
        id: this.id,
        data: form_data,
      };

      store
        .dispatch("updateLicenseForm", license)
        .then((response) => {
          this.fetchData();
          console.log(response.data);
          this.id = "";
          this.name = "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteLicense() {
      store
        .dispatch("deleteLicenseForm", this.id)
        .then((response) => {
          this.fetchData();
          console.log(response.data);
          this.id = "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchData() {
      this.$store
        .dispatch("fetchLicenseForms", this.categoryId)
        .then((response) => {
          console.log(response.data);
          const logistics = response.data.AllLicenseForms;
          this.licenseForms = logistics;
        })
        .catch((err) => {
          console.log("err", err);
          this.error = "Network Error";
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  computed: {
    // ...mapGetters(["licenses"]),
    function() {
      return url;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style></style>
