<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card border-left-danger">
          <div class="card">
            <div class="card-body">
              <div class="row mt-4">
                <div class=" col-md-12">
                  <pdf :src="url + file"></pdf>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      url: `https://staging.afcfta.app`,
      file: this.$route.params.file,
    };
  },
};
</script>

<style></style>
