<template>

    <div class="">
        <Alert v-bind:message='message' type="alert-success" v-if="message" />
        <Alert v-bind:message='error' type="alert-danger" v-if="error" />
        <div class="row">
            <div class="col-12 my-3">
                <h2 class="my-3 text-secondary" style="font-size:1.2em; display:inline-block">Assign Editor</h2>
                <router-link to="/editors" style="float:right;" class="btn btn-danger my-2">
                    <i class="fas fa-pen-alt"></i> Add Editor
                </router-link>
            </div>
        </div>
    
       
        <div class="card shadow-sm mb-5">
            <div class="card-header">
                <h3 class="my-2 text-secondary" style="font-size:1em;">Users</h3>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table
                        class="table table-bordered table-hover"
                        id="dataTable"
                    >
                    <thead>
                        <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(admin, key) in users" :key="key">
                        <td>{{admin.name}}</td>
                        <td>{{admin.email}}</td>
                        <td>{{admin.role}}</td>
                        <td>
                            <button 
                            @click="getId(admin.id,key)" 
                            data-toggle="modal"
                            data-target="#addGlobalAdmin"
                            class="btn action btn-info mb-1 mr-3">
                                assign editor
                            </button>
                
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
         
      
  <!-- Modal contents -->
  <div class="modal fade" id="addGlobalAdmin"
     tabindex="-1" aria-labelledby="add market" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Assign Editor</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">                
                 <div v-if="!addSuccess" class="alert alert-warning">
                     <p class="">
                         You are given this user access to edit afcfta non-creative content.
                         <br>Are you are sure?
                     </p>
                 </div>
                 <div class="alert alert-danger" v-if="addError">
                     <p>{{addError}}</p>
                 </div>
                 <div class="alert alert-success" v-if="addSuccess">
                     <p>{{addSuccess}}</p>
                 </div>
                 <div class="modal-footer" v-if="!addSuccess">
                     <button class="btn btn-secondary" data-dismiss="modal">
                     No, cancel
                 </button>
                  <button @click="assignEditor" style="float:right" type="submit" class="btn btn-danger my-2 btn-lg">
                      Yes, Continue
                      <RoundSpinner v-if="loading" />
                  </button>
                 </div>
                 <div v-if="addSuccess" class="modal-footer">
                     <button  data-dismiss="modal" class="btn btn-danger">Close</button>
                 </div>
            </div>
          </div>
        </div>
    </div>
</div>
</template>     
<script>
import Alert from '@/components/Alert'
import RoundSpinner from '@/components/RoundSpinner'
import { mapGetters } from 'vuex'
const $  = require('jquery')
// import Table from '../admin/Table'

export default {
    name:'assign-editors',
    components: {
        Alert:Alert,
        RoundSpinner:RoundSpinner,
        // Table:Table
    },
    data(){
        return {
            addSuccess:null,
            addError:null,
            message:null,
            error:null,
            id:null,
            loading:false,
        }
    }
    ,
    methods:{
        getId(id) {
            this.id = id
        },
        assignEditor(){
          this.loading = true

            this.$store.dispatch('assignEditor', this.id)
                .then(response => {
                    this.addSuccess = response.data.message
                }).catch(err => {
                    if(err) {
                      this.loading = false
                      this.adderror = err.response.data.message
                    }
                })
        },
        getUsers() {
           this.$store.dispatch('getUsers').then(response => setTimeout(function(){$('#dataTable').DataTable();}, 100))
            .catch(err => {
              if(err?.response?.data === undefined) {
                this.error = 'Network Error.'
              }
            })
        },

       
    },
    computed: {
        ...mapGetters(['users'])
    },
    created() {
        this.getUsers()
    } 
    
}
</script>
<style scoped>
th{
    font-weight: bolder;
}
.nav-link{
    color: #ba2428;
}
/* a#pills-profile-tab.nav-link.active,a#pills-home-tab.nav-link.active{
    background-color:#ba2428;
    color:#fff;
} */
.action{
    width:110px;

}
button, table tr td{
    font-size: .85em;
}
</style>