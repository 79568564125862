<template>
  <span>
    <div class="row text-content" v-for='(critique,key) in resolution' :key="key">
        
    <div class="col-12">
        <p><strong>Resolution</strong> {{critique.text}}</p>
            <div class="d-flex">
                        <a class="p-2" v-if="critique.pdf" :href="`${url()}${critique.pdf}`" target="_blank" download>pdf</a>
                        <a  class="p-2" v-if="critique.doc" :href="`${url()}${critique.doc}`" target="_blank" download>document</a>
                        <a  class="p-2"  v-if="critique.pic" :href="`${url()}${critique.pic}`" target="_blank" download>image</a>
                        <a  class="p-2" v-if="critique.video" :href="`${critique.video}`" target="_blank" >Youtube</a>
                    </div>
            <div class="row">
                <div class="col-md-12">
                    <p><small>created at:</small> <small>{{critique.created_at | moment}}</small></p> 
                    <p><small>created by:</small> <small>{{critique.created_by.first_name}} {{critique.created_by.last_name}}</small></p> 
                </div>
            
            <button v-if="!critique.approved" @click="approve(critique.id)" class="btn btn-success btn-sm smallBtn">
                Approve
            </button>
            <button v-if="critique.approved" @click="disapprove(critique.id)" class="btn btn-info btn-sm smallBtn mr-2">
                Disapprove
            </button>
            <button class="btn btn-danger btn-sm mr-2" data-toggle="modal" data-target="#resolutionModal">
                Delete
            </button>
            <button class="btn btn-warning btn-sm ">
                Update
            </button>
        </div>
        <hr>
    </div>
    <!-- Modal -->
        <div class="modal fade" id="resolutionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Resolution</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Are you sure you want to do this ?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-danger" @click="deleteCritique(critique.id)">Confirm</button>
            </div>
            </div>
        </div>
        </div>
    </div>
  </span>
</template>

<script>
import toasterMixin from '../../mixins/toasterMixin'
import moment from 'moment'
import url from '../../url'
export default {
    name:'resolution',
    props: {
        resolution:Array
    },
      filters: {
            moment: function (date) {
                return moment(date).format('MMMM Do YYYY');
            }
            },
    methods: {
        url() {
            return url()
        },
        approve(id) {
          const data = {
              id:id,
              approve: {
                   "approved": true
              }
          }
        
          this.$store.dispatch('approveOrDisapproveSynthesis',data)
            .then(response => {
                 window.location.reload()
                this.toast({
                    message:'thesis approved.',
                    type:'success'
                })
               
            }).catch(err => {
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
                  if(err.response.status == 401) {
                   this.toast({
                    message:err.response.data.message,
                    type:'error'
                })  
                }
            })
      },
      disapprove(id) {
          const data = {
              id:id,
              approve: {
                   "approved": false
              }
          }
          this.$store.dispatch('approveOrDisapproveSynthesis',data)
            .then(response => {
                this.toast({
                    message:'Resolution disapproved.',
                    type:'success'
                })
                 window.location.reload()
            }).catch(err => {
                
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
            })
      },
    },
    created() {
        console.log('resolution',this.resolution)
    }
}
</script>

<style>

</style>