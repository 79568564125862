<template>
    <div>
        <div class="row">
        <div class="col-12 my-3">
            <h2 class="my-3  text-secondary" style="font-size:1.2em; display:inline-block">Articles</h2>
        </div>
      </div>

        <div class="card shadow-sm mb-5">

             <div class="card-header">
                  <h3 class="my-2 text-secondary" style="font-size:1em;">Articles</h3>
              </div>

            <div class="card-body">
            <div class="table-responsive">
                <table
                class="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
                >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>CREATED AT</th>
                        <th>ARTICLE</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(article, key) in articles" :key="key">
                        <td>{{article.id}}</td>
                        <td>
                            {{article.created_at | moment}}
                        </td>
                        <td>
                            <div class="card">
                        <div class="card-body">
                            <p>
                                <strong>Topic</strong>   {{article.topic}}
                            </p>
                            <p>
                                <strong>Body</strong> {{article.body}}
                            </p>
                                <div class="d-flex justify-content-between my-2">
                                    <p><strong>created By:</strong> {{article.created_by.first_name}} {{article.created_by.last_name}}</p>
                                    <!-- <button class="btn btn-success" @click="contributions(article.id)">View Comments</button> -->
                                    <button v-if="!article.approved" @click="approve(key, article.id)" class="btn btn-success btn-sm smallBtn">
                                        Approve
                                </button>
                                    <button v-if="article.approved" @click="disapprove(key, article.id)" class="btn btn-info btn-sm smallBtn">
                                        Disapprove
                                </button>
                                <button  class="btn btn-danger btn-sm smallBtn" data-toggle="modal" data-target="#exampleModal">
                                        Delete
                                </button>
                                </div>
                        </div>
                    </div>

                                <!-- Modal -->
                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Delete Critique</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Are you sure you want to do this ?
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-danger" @click="deleteArticle(article.id)" data-dismiss="modal">Confirm</button>
                        </div>
                        </div>
                    </div>
                    </div>

                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import toasterMixin from '../../mixins/toasterMixin'
import moment from 'moment'
const $ = require('jquery')

export default {
    name:'debate-articles',
    components: {
    },
    data() {
      return {
        id:null,
        success:null,
      }
    },
    filters: {
        moment: function (date) {
                return moment(date).format('MMMM Do YYYY');
            }
    },
    methods: {
      contributions(id) {
        this.$router.replace(`/blog/challenge/${id}/contributions`)
      },
      approve(key,id) {
          const data = {
              key:key,
              id:id,
              approve: {
                   "approved": true
              }
          }
          this.$store.dispatch('approveOrDisapproveArticle',data)
            .then(response => {
                this.toast({
                    message:`Comment Approved`,
                    type:'success'
                })
            }).catch(err => {
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
            })
      },
      disapprove(key,id) {
          const data = {
              key:key,
              id:id,
              approve: {
                   "approved": false
              }
          }
          this.$store.dispatch('approveOrDisapproveArticle',data)
            .then(response => {
                this.toast({
                    message:`Comment disapproved.`,
                    type:'success'
                })
            }).catch(err => {
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
            })
      },
      fetchArticles() {
          this.$store.dispatch('fetchArtcles')
          .then(response => {
              console.log('')
              setTimeout(function(){$('#dataTable').DataTable();}, 5)
          })
            .catch(err => {
              if(err?.response?.data === undefined) {
                this.toast({
                    message:'Network error. Check Network connection and try again.',
                    type:'error'
                })
              }
            })
      },
      deleteArticle(id){
          this.$store.dispatch('deleteArticle',id)
          .then(response =>{
              console.log(response)
              this.fetchArticles() 
          })
          .catch(err=>{
               if(err?.response?.data === undefined) {
                this.toast({
                    message:'Network error. Check Network connection and try again.',
                    type:'error'
                })
              }
          })
      }
    },
    computed: { 
      ...mapGetters(['articles'])
     },
    created() {
          this.fetchArticles()
        },
}
</script>
<style scoped>
  button, table tr td{
    font-size: .9em;
  }
  .smallBtn{
      font-size: .8em;
      padding-top:5px;
      padding-bottom:5px;
  }
    .table-title{
    padding:10px;
    margin-bottom: 5px;
    background: linear-gradient(40deg,#ff2020, #05ffa3);
    color: #fff;
    border-radius: 1px 1px 0 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title h2{
    font-size: 24px;
}
</style>