<template>
        <div>
        <div class="row">
        <div class="col-12 my-3">
            <h2 class="my-3  text-secondary" style="font-size:1.2em; display:inline-block">Challenges</h2>
        </div>
      </div>
            
        <div class="card shadow-sm mb-5">

            <div class="card-header">
                  <h3 class="my-2 text-secondary" style="font-size:1em;">Challenges</h3>
              </div>

            <div class="card-body">
            <div class="table-responsive">
                <table
                class="table table-striped table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
                >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>CREATED ON</th>
                        <th>LEADER'S VISIONS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(challenge, key) in leadersVisions" :key="key">
                        <td>{{challenge.id}}</td>
                        <td>
                            {{challenge.created_at | moment}}
                        </td>
                        <td>
                            <div class="card">
                        <div class="card-body">
                            <p>
                                <strong>Issue Area : </strong> {{challenge.issue_area[0]}}
                            </p>
                            <p>
                                <strong>Topic : </strong>   {{challenge.heading}}
                            </p>
                            <p>
                                <strong>Body : </strong> {{challenge.summary}}
                            </p>
                            <div class="d-flex">
                                <a class="p-2" v-if="challenge.pdf" :href="`${url()}${challenge.pdf}`" target="_blank" download>pdf</a>
                                <a  class="p-2" v-if="challenge.doc" :href="`${url()}${challenge.doc}`" target="_blank" download>document</a>
                                <a  class="p-2"  v-if="challenge.image" :href="`${url()}${challenge.image}`" target="_blank" download>image</a>
                                <a  class="p-2" v-if="challenge.video" :href="`${challenge.video}`" target="_blank" >Youtube</a>
                            </div>
                                <div class="d-flex justify-content-between my-2">
                                    <p><small>created on:</small> <small>{{challenge.created_at | moment}}</small></p>
                                    <p><small>created by:</small> <small>{{challenge.created_by[0].first_name}} {{challenge.created_by[0].last_name}}</small></p>
                                <!-- <p><small>email:</small> <small>{{challenge.email}}</small></p> -->
                                    <!-- <button v-if="challenge.approved" class="btn btn-success"
                                        @click="contributions(challenge.id)">
                                        View Contributions
                                    </button> -->
                                    <!-- <button v-if="!challenge.approved" @click="approve(key, challenge.id)" class="btn btn-success btn-sm smallBtn">
                                        Approve
                                </button>
                                    <button v-if="challenge.approved" @click="disapprove(key, challenge.id)" class="btn btn-danger btn-sm smallBtn">
                                        Disapprove
                                </button> -->
                                </div>
                        </div>
                    </div>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>

        </div>
       <!-- start issue mandate modal content -->
      <!-- end of issue mandate modal content -->

</template>
<script>
import { mapGetters } from 'vuex'
import toasterMixin from '../../mixins/toasterMixin'
import moment from 'moment'
import url from '../../link'
const $ = require('jquery')
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    name:'debate-articles',
    components: {
    },
    data() {
      return {
        id:null,
        success:null,
      }
    },
    filters: {
            moment: function (date) {
                return moment(date).format('MMMM Do YYYY');
            }
            },
    methods: {
      url() {
          return url()
      },
      contributions(id) {
        this.$router.replace(`/blog/challenge/${id}/contributions`)
      },

      approve(key,id) {
          const data = {
              key:key,
              id:id,
              approve: {
                   "approved": true
              }
          }
          this.$store.dispatch('approveOrDisapproveChallengeLeadersVisions',data)
            .then(response => {
                this.toast({
                    message:`Leader's Vision Approved`,
                    type:'success'
                })
            }).catch(err => {
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
            })
      },
      disapprove(key,id) {
          const data = {
              key:key,
              id:id,
              approve: {
                   "approved": false
              }
          }
          this.$store.dispatch('approveOrDisapproveChallengeLeadersVisions',data)
            .then(response => {
                this.toast({
                    message:`Leader's Vision disapproved.`,
                    type:'success'
                })
            }).catch(err => {
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
            })
      },
      fetchDebateArticles() {
          this.$store.dispatch('fetchDebateLeadersVisions')
          .then( response => {
              console.log(response)
              setTimeout(function(){$('#dataTable').DataTable({
                dom: 'Bfrtip',
                buttons: [
                    'csv', 'pdf', 
                ]
          });}, 100)
          })
            .catch(err => {
              if(err?.response?.data === undefined) {
                this.toast({
                    message:'Network error. Check Network connection and try again.',
                    type:'error'
                })
              }
            })
      }
    },
    computed: { 
      ...mapGetters(['leadersVisions'])
     },
    created() {
          this.fetchDebateArticles() 
        },
}
</script>
<style scoped>
  button, table tr td{
    font-size: .9em;
  }
  .smallBtn{
      font-size: .8em;
      padding-top:5px;
      padding-bottom:5px;
  }

.table-title{
    padding:10px;
    margin-bottom: 5px;
    background: linear-gradient(40deg,#ff2020, #05ffa3);
    color: #fff;
    border-radius: 1px 1px 0 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title h2{
    font-size: 24px;
}
</style>