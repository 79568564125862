<template>
  <div class="">
      <Alert v-bind:message='error' type="alert-danger" v-if="error" />
       <div class="row">
            <div class="col-12 my-3">
                <h2 class="my-3 text-secondary" style="font-size:1.2em; display:inline-block">Editors</h2>
                <router-link to="/assign_editor" style="float:right;"
                    class="btn btn-danger my-2">
                    Back
                </router-link>
            </div>
        </div>
    
       
        <div class="card shadow-sm mb-5">
            <div class="card-header">
                <h3 class="my-2 text-secondary" style="font-size:1em;">Editors</h3>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table
                        class="table table-bordered table-hover"
                        id="dataTable"
                    >
                    <thead>
                        <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(admin, key) in editors" :key="key">
                        <td>{{admin.name}}</td>
                        <td>{{admin.email}}</td>
                        <td>{{admin.role}}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
         
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const $  = require('jquery')
export default {
    name:'editors',
    data() {
        return {
            error:''
        }
    },
        computed: {
        ...mapGetters(['editors'])
    },
    created() {
         this.$store.dispatch('getEditors').then(response => setTimeout(function(){$('#dataTable').DataTable();}, 100))
            .catch(err => {
              if(err?.response?.data === undefined) {
                this.error = 'Network Error.'
              }
            })
    }
}
</script>

<style>

</style>