<template>
  <div class="container-fluid">
    <Alert :message="success" type="alert alert-success" v-if="success" />
    <Alert :message="error" type="alert alert-danger" v-if="error" />
    <div class="row d-flex justify-content-end">
      <router-link
        :to="`/e-logistics/${entityId}/customize-license-form`"
        class="btn m-3 p-1 btn-primary"
      >
        Customize License Form
      </router-link>
    </div>
    <div class="row">
      <div class="col">
        <div class="card border-left-danger">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-6">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select
                              v-model="perPage"
                              size="sm"
                              :options="pageOptions"
                            ></b-form-select
                            >&nbsp;entries
                          </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-6">
                        <div
                          id="tickets-table_filter"
                          class="dataTables_filter text-md-right"
                        >
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filter"
                              type="search"
                              placeholder="Search..."
                              class="form-control form-control-sm ml-2"
                            ></b-form-input>
                          </label>
                        </div>
                      </div>
                      <!-- End search -->
                    </div>
                    <div class="table-responsive" style="min-height: 200px;">
                      <b-table
                        :items="elogistics"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sorrt-desc.sync="sortDesc"
                        :fit-by.sync="sortBy"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        hover
                      >
                        <template v-slot:cell(action)="row">
                          <div class="dropdown">
                            <a
                              href="#"
                              data-toggle="dropdown"
                              data-caret="false"
                              class="text-muted"
                              ><b-icon-three-dots></b-icon-three-dots
                            ></a>
                            <div class="dropdown-menu dropdown-menu-right p-3">
                              <router-link
                                :to="
                                  `/e-logistics/${entityId}/license-categories/${row.item.id}`
                                "
                                class="btn text"
                              >
                                License Categories
                              </router-link>
                              <router-link
                                :to="
                                  `/e-logistics/${entityId}/requirement-types/${row.item.id}`
                                "
                                class="btn text"
                              >
                                Requirement types
                              </router-link>
                              <router-link
                                :to="
                                  `/e-logistics/${entityId}/additional-requirements/${row.item.id}`
                                "
                                class="btn text"
                              >
                                Additional Requirement
                              </router-link>
                            </div>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Alert";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  components: {
    Alert: Alert,
  },
  data() {
    return {
      //  data table
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "name", sortable: true, label: "Name" },
        { key: "type", sortable: true, label: "Type" },
        { key: "profile_status", sortable: true, label: "Status" },
        { key: "Action" },
      ],

      //   table data

      id: null,
      elogistics: [],
      entityId: this.$route.params.id,
      key: null,
      success: null,
      error: null,
    };
  },
  methods: {
    fetchData() {
      this.$store
        .dispatch("fetchLogistics", this.entityId)
        .then((response) => {
          const logistics = response.data.LogisticsRegulators;
          this.elogistics = logistics;
          console.log(logistics);
        })
        .catch((err) => {
          console.log("err", err);
          this.error = "Network Error";
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  computed: {
    rows() {
      return this.elogistics && this.elogistics.length;
    },
    // ...mapGetters(["alllogistics"]),
  },
  created() {
    this.fetchData();
  },
};
</script>

<style></style>
