<template>
  <div class="container-fluid">
    <Alert :message="success" type="alert alert-success" v-if="success" />
    <Alert :message="error" type="alert alert-danger" v-if="error" />
    <div class="row">
      <div class="col">
        <div class="card border-left-danger">
          <div class="card-body">
            <b-form @submit="onSubmit">
              <b-form-group
                id="input_type"
                label="License Category"
                label-for="input_type"
                class="mx-3 w-80"
              >
                <multiselect
                  v-model="cate"
                  track-by="name"
                  label="categories"
                  placeholder="Select Category"
                  :options="categories"
                  :custom-label="getCategories"
                  :close-on-select="true"
                  @select="getCategoriesDetails($event)"
                >
                </multiselect>
              </b-form-group>
              <div v-for="(form, index) in forms" :key="index">
                <div class="d-flex">
                  <b-form-group id="label" label="Label" label-for="label">
                    <b-form-input
                      id="label"
                      v-model="form.label"
                      type="text"
                      placeholder="Enter Label"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input_type"
                    label="Input Type"
                    label-for="input_type"
                    class="mx-3 w-25"
                  >
                    <b-form-select
                      id="input_type"
                      v-model="form.input_type"
                      :options="options"
                      @change="getInputType($event)"
                      size="sm"
                      class="w-100"
                    ></b-form-select>
                  </b-form-group>
                  <div class="d-flex mt-4">
                    <b-form-checkbox class="pt-2" v-model="form.required"
                      >Required</b-form-checkbox
                    >
                    <b-icon
                      @click="removeInput(index)"
                      class="mt-3 ml-4"
                      icon="x-circle"
                      scale="2"
                      variant="danger"
                    ></b-icon>
                  </div>
                </div>
                <div v-if="isSelected(form.input_type)">
                  <!-- <b-form-input
                    id="label"
                    v-model="form.options"
                    type="input"
                    placeholder="Enter option"
                  ></b-form-input> -->
                  <div>
                    <label for="tags-basic"
                      >Type a new tag and press enter</label
                    >
                    <b-form-tags
                      input-id="tags-basic"
                      v-model="form.options"
                    ></b-form-tags>
                    <!-- <p class="mt-2">Value: {{ value }}</p> -->
                  </div>
                </div>
              </div>

              <div class="py-3 d-flex justify-content-center">
                <b-button type="primary" @click="addInput">Add Input</b-button>
              </div>
              <b-button type="submit" variant="primary">Submit</b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Alert";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  components: {
    Alert: Alert,
  },
  data() {
    return {
      //  data table
      cate: "",
      license_category: "",
      forms: [
        {
          label: "",
          input_type: "",
          required: false,
        },
      ],
      isInput: "",
      //   table data

      id: null,
      selected: null,
      count: 1,
      options: [
        "text",
        "number",
        "textarea",
        "date",
        "file",
        "select",
        "radio",
        "checkbox",
      ],
      categories: [],
      entityId: this.$route.params.id,
      key: null,
      success: null,
      error: null,
    };
  },
  methods: {
    getInputType(event) {
      console.log(event);
      if (event == "date") {
        this.isInput = "";
      } else if (event == "file") {
        this.isInput = "file";
      } else if (event == "select") {
        this.isInput = "select";
      } else if (event == "checkbox") {
        this.isInput = "checkbox";
      } else if (event == "radio") {
        this.isInput = "radio";
      } else {
        this.isInput = "";
      }
    },
    removeInput(index) {
      this.forms.splice(index, 1);
    },
    getCategories({ name }) {
      return `${name}`;
    },
    addInput() {
      const input = { label: "", input_type: "", required: false };
      this.forms.push(input);
    },
    getCategoriesDetails(event) {
      console.log(event);
      this.license_category = event.id;
    },
    fetchData() {
      this.$store
        .dispatch("fetchLicenseCategories")
        .then((response) => {
          const logistics = response.data.AllLicenseCategories;
          this.categories = logistics;
          console.log(logistics);
        })
        .catch((err) => {
          console.log("err", err);
          this.error = "Network Error";
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onSubmit(e) {
      e.preventDefault();
      const data = {
        _meta_data: this.forms,
        license_category: this.license_category,
      };
      console.log("data", data);
      this.$store
        .dispatch("createLicenseForm", data)
        .then((response) => {
          console.log(response);
          if (response.status == 201) {
            this.fetchData();
            this.toast({
              message: "Additional Type Created!",
              type: "success",
            });
            this.name = "";
          }
        })
        .catch((err) => {
          console.log("err", err);
          if (err?.response?.data) {
            this.toast({ message: err?.response?.data.error, type: "error" });
          }
        });

      console.log("submit", this.forms);
    },
    isSelected(input) {
      let valid = false;
      if (input == "select") {
        valid = true;
      } else if (input == "file") {
        valid = true;
      } else if (input == "radio") {
        valid = true;
      } else if (input == "check") {
        valid = true;
      }
      return valid;
    },
  },
  computed: {
    rows() {
      return this.categories && this.categories.length;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style></style>
