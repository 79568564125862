<template>
  <div class="container-fluid">
    <Alert :message="success" type="alert alert-success" v-if="success" />
    <Alert :message="error" type="alert alert-danger" v-if="error" />

    <!-- new license modal open -->
    <div
      class="modal fade"
      id="newcategory"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Create License Category
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group mx-3">
              <div class="form-group ml-2 mt-5">
                <label for="name">Name</label>
                <input
                  v-model="name"
                  type="text"
                  id="name"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              @click="newCategory"
              type="submit"
              class="btn btn-success"
              data-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- new license modal close -->

    <!-- edit license modal open -->
    <div
      class="modal fade"
      id="editlicense"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Update License Category
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group ml-2 mt-5">
              <label for="name">Name</label>
              <input
                v-model="name"
                type="text"
                id="name"
                class="form-control"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              @click="updateLicense"
              type="submit"
              class="btn btn-success"
              data-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- edit license modal close -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="deletecategory"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Delete Category</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to do this ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              @click="deleteLicense"
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal end -->

    <div class="row d-flex justify-content-between">
      <div></div>
      <button
        @click="getKeyAndId(logisticId)"
        data-toggle="modal"
        data-target="#newcategory"
        class="btn m-3 p-1 btn-primary"
      >
        New Category
      </button>
    </div>
    <div class="row">
      <div class="col">
        <div class="card border-left-danger">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-6">
                        <div
                          id="tickets-table_length"
                          class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select
                              v-model="perPage"
                              size="sm"
                              :options="pageOptions"
                            ></b-form-select
                            >&nbsp;entries
                          </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-6">
                        <div
                          id="tickets-table_filter"
                          class="dataTables_filter text-md-right"
                        >
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filter"
                              type="search"
                              placeholder="Search..."
                              class="form-control form-control-sm ml-2"
                            ></b-form-input>
                          </label>
                        </div>
                      </div>
                      <!-- End search -->
                    </div>
                    <div class="table-responsive">
                      <b-table
                        :items="licenseCategories"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sorrt-desc.sync="sortDesc"
                        :fit-by.sync="sortBy"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        hover
                      >
                        <template v-slot:cell(action)="row">
                          <div class="dropdown">
                            <a
                              href="#"
                              data-toggle="dropdown"
                              data-caret="false"
                              class="text-muted"
                              ><b-icon-three-dots></b-icon-three-dots
                            ></a>
                            <div class="dropdown-menu dropdown-menu-right p-3">
                              <router-link
                                :to="
                                  `/e-logistics/${entityId}/license-forms/${row.item.id}`
                                "
                                class="btn text-primary"
                              >
                                License Forms
                              </router-link>
                              <router-link
                                :to="
                                  `/e-logistics/${entityId}/license-requirements/${row.item.id}`
                                "
                                class="btn text-primary"
                              >
                                Check License Requirements
                              </router-link>
                              <button
                                @click="getId(row.item.id, row.item.name)"
                                data-toggle="modal"
                                data-target="#editlicense"
                                class="btn m-0 p-1 text"
                              >
                                Edit Category
                              </button>
                              <button
                                @click="getKeyAndId(row.item.id)"
                                data-toggle="modal"
                                data-target="#deletecategory"
                                class="btn m-0 p-1 text-danger"
                              >
                                Delete Category
                              </button>
                            </div>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from "@/components/Alert";
import { mapGetters } from "vuex";
// import store from "@/store";
import ToasterMixin from "@/mixin/toasterMixin.js";

export default {
  components: {
    Alert: Alert,
  },
  data() {
    return {
      //  data table
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "name", sortable: true, label: "Name" },
        { key: "logistics_regulator.type", sortable: true, label: "Type" },
        {
          key: "logistics_regulator.profile_status",
          sortable: true,
          label: "Status",
        },
        { key: "Action" },
      ],

      //   table data
      logisticId: this.$route.params.logisticId,
      entityId: this.$route.params.id,
      id: null,
      licenseCategories: [],
      key: null,
      success: null,
      error: null,
      name: "",
    };
  },
  methods: {
    getKeyAndId(id) {
      this.id = id;
    },
    getId(id, name) {
      this.id = id;
      this.name = name;
    },
    newCategory() {
      const data = {
        logistics_regulator: this.logisticId,
        name: this.name,
      };

      this.$store
        .dispatch("addLicenseCategory", data)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.fetchData();
            this.toast({
              message: "License Category Created!",
              type: "success",
            });
            this.name = "";
            this.id = "";
          }
        })
        .catch((err) => {
          console.log("err", err);
          if (err?.response?.data) {
            this.toast({ message: err?.response?.data.error, type: "error" });
          }
        });
    },
    updateLicense() {
      const license = {
        data: {
          logistics_regulator: this.logisticId,
          name: this.name,
        },
        id: this.id,
      };

      this.$store
        .dispatch("updateLicenseCategory", license)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.fetchData();
            this.toast({
              message: "License Category Updated!",
              type: "success",
            });

            this.name = "";
            this.id = "";
          }
        })
        .catch((err) => {
          console.log("err", err);
          if (err?.response?.data) {
            this.toast({ message: err?.response?.data.error, type: "error" });
          }
        });
    },
    deleteLicense() {
      this.$store
        .dispatch("deleteLicenseCategory", this.id)
        .then((response) => {
          console.log(response);
          if (response.status == 204) {
            this.fetchData();
            this.toast({
              message: "License Category Deleted!",
              type: "error",
            });
            this.name = "";
            this.id = "";
          }
        })
        .catch((err) => {
          console.log("err", err);
          if (err?.response?.data) {
            this.toast({ message: err?.response?.data.error, type: "error" });
          }
        });
    },
    fetchData() {
      this.$store
        .dispatch("fetchLicenseCategories")
        .then((response) => {
          console.log(response.data);
          const logistics = response.data.AllLicenseCategories;
          this.licenseCategories = logistics;
        })
        .catch((err) => {
          console.log("err", err);
          this.error = "Network Error";
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  computed: {
    rows() {
      return this.licenseCategories && this.licenseCategories.length;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style></style>
