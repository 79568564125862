<template>
  <div class="row">
          <button style="float:left" class="my-2 btn btn-danger" @click="back">back</button>
      <div class="col-12" >
          <div class="card">
              <div class="card-body" v-if="debateArticle.thesis && debateArticle.thesis.length == 0">
                  No Contrbutions Available
              </div>
          </div>
      </div>
      <div class="col-12" v-if="debateArticle.thesis">
          <div class="row">
              <div class="col-12">
                  <div class="row" v-for="(thesis,key) in debateArticle.thesis" :key="key">
                        
                        <div class="col-12">
                            <p>
                            <strong>Leader's Opinion</strong>   {{thesis.text}}
                            </p>
                                <div class="d-flex">
                                    <a class="p-2" v-if="thesis.pdf" :href="`${url()}${thesis.pdf}`" target="_blank" download>pdf</a>
                                    <a  class="p-2" v-if="thesis.doc" :href="`${url()}${thesis.doc}`" target="_blank" download>document</a>
                                    <a  class="p-2"  v-if="thesis.pic" :href="`${url()}${thesis.pic}`" target="_blank" download>image</a>
                                    <a  class="p-2" v-if="thesis.video" :href="`${thesis.video}`" target="_blank" >Youtube</a>
                                </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p><small>created at:</small> <small>{{thesis.created_at | moment}}</small></p> 
                                    <p><small>created by:</small> <small>{{thesis.created_by.first_name}} {{thesis.created_by.last_name}}</small></p>
                                </div>
                            
                            <button v-if="!thesis.approved" @click="approve(key, thesis.id)" class="btn btn-success btn-sm smallBtn">
                            Approve
                            </button>
                            <button v-if="thesis.approved" @click="disapprove(key, thesis.id)" class="btn btn-info btn-sm smallBtn mr-2">
                            Disapprove
                            </button>
                            <button class="btn btn-danger btn-sm mr-2" data-toggle="modal" data-target="#leadOpinion">
                                Delete
                            </button>
                            <button class="btn btn-warning btn-sm">
                                Update
                            </button>
                            </div>
                             <hr>
                        </div>
                           
                                    <!-- Modal -->
                        <div class="modal fade" id="leadOpinion" tabindex="-1" role="dialog" aria-labelledby="leadOpinion" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Delete Leader's Opinion </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                Are you sure you want to do this ?
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-danger">Confirm</button>
                            </div>
                            </div>
                        </div>
                        </div>
                        
                        <div class="col-12" v-if="thesis.antithesis">
                            <Critique :critique='thesis.antithesis' />
                        </div> 
                        <!-- <div class="col-6" v-if="thesis.synthesis">
                            <div class="col">
                                {{thesis.synthesis}}
                            </div>
                            <Resolution :resolution="thesis.synthesis" />
                        </div> -->
                    </div>
                    <hr>
              </div>
          </div>
      </div>
      

  </div>
</template>

<script>
import Critique from './Critique'
import moment from 'moment'
import url from '../../link.js'
import { mapGetters } from 'vuex'
export default {
    name:'debate-contributions',
    components:{
        Critique,
    },
    data() {
        return {
            debateArticle:{}
        }
    },
      filters: {
            moment: function (date) {
                return moment(date).format('MMMM Do YYYY');
            }
            },
    methods: {
        url(){
            return url()
        },
        back() {
            this.$router.push('/blog/debate-articles')
        },
         approve(key,id) {
          const data = {
              key:key,
              id:id,
              approve: {
                   "approved": true
              }
          }
          this.$store.dispatch('approveOrDisapproveThesis',data)
            .then(response => {
                this.toast({
                    message:'thesis approved.',
                    type:'success'
                })
                window.location.reload()
            }).catch(err => {
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
                if(err.response.status == 401) {
                   this.toast({
                    message:err.response.data.message,
                    type:'error'
                })  
                }
            })
      },
      disapprove(key,id) {
          const data = {
              key:key,
              id:id,
              approve: {
                   "approved": false
              }
          }
          this.$store.dispatch('approveOrDisapproveThesis',data)
            .then(response => {
                this.toast({
                    message:'thesis disapproved.',
                    type:'success'
                })
                window.location.reload()
            }).catch(err => {
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
            })
      },
      fetchDebateArticles() {
          this.$store.dispatch('fetchDbateArtcles')
            .then(response => {
                this.debateArticle = response.data.find( debate => debate.id == this.$route.params.id)
                console.log('articles', this.debateArticle)
            })
            .catch(err => {
              if(err?.response?.data === undefined) {
                this.toast({
                    message:'Network error. Check Network connection and try again.',
                    type:'error'
                })
              }
            })
      }
    },
    
    created() {
       this.fetchDebateArticles() 
       
    },
}
</script>

<style scoped>
    h2{
        font-size: 1.5em;
    }
</style>