<template>
  <div>
    <div class="card" v-if="!loading && !success">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-center my-5">
          <p>No Resource Found. Try Again</p>
        </div>
      </div>
    </div>
    <div
      class="container card content_margin_bottom pt-4"
      v-if="!loading && success"
    >
      <div class="table-wrapper">
        <table class="table table-borderless">
          <thead>
            <tr>
              <td scope="col"><b>Input Type</b></td>
              <td scope="col">Label</td>
              <td scope="col">Options</td>
              <td scope="col">Required</td>
            </tr>
            <hr />
          </thead>
          <tbody>
            <tr v-for="data in category._meta_data" :key="data.id">
              <td>
                <b> {{ data.input_type }} </b>
              </td>
              <td>
                {{ data.label }}
              </td>
              <td v-if="data.options">
                <p v-for="(option, index) in data.options" :key="index">
                  {{ option }}
                </p>
              </td>
              <td v-else>
                -
              </td>
              <td>{{ data.required }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
// import toasterMixin from "@/mixins/toasterMixin.js";
export default {
  name: "License Requirements",
  //   page: {
  //     title: "License Requirements",
  //     meta: [{ name: "description", content: appConfig.description }],
  //   },
  data() {
    return {
      category: {},
      entityID: this.$route.params.id,
      category_id: this.$route.params.category_id,
      loading: false,
      addLoading: false,
      success: false,
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$store
        .dispatch("fetchCustomizeLicenseForm", this.category_id)
        .then((response) => {
          console.log(response);
          const category = response.data;
          this.category = category;
          this.loading = false;
          this.success = true;
        })
        .catch(() => {
          this.loading = false;
          this.success = false;
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style scoped>
b {
  font-weight: bolder;
}
.table-wrapper {
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.247);
}
.company_logo {
  width: 100%;
  max-width: 300px;
}
</style>
