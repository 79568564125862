<template>
  <div class="">

    <div class="row">
        <div class="col-12 my-3">
            <h2 class="my-3 text-secondary" style="font-size:1.2em;">Payments & Transactions</h2>
       </div>
    </div>   
        
    <div class="card shadow-sm mb-5">
        <div class="card-header">
            <h3 class="text-secondary my-3" style="font-size:1em;">Payments & Transactions</h3>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table
                class="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
                >
                <thead>
                    <tr>
                    <th>Date Issued</th>
                    <th>Token Name</th>
                    <th>Expire</th>
                    <th>Units</th>
                    <th>Value Type</th>
                    <th>Market</th>
                    <th>Value</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(token, key) in allTokens" :key="key">
                    <td>
                        {{token.date_issued | moment}}
                    </td>
                    <td>
                        {{token.name}}
                    </td>
                    <td>
                        {{token.expire}}
                    </td>
                    <td>
                        {{token.units}}
                    </td>
                    <td>
                        {{token.value_type}}
                    </td>
                    <td>
                        {{token.market}}
                    </td>
                    <td>
                        {{token.value}}
                    </td>
                    <td>
                        <button @click="getID(token.id)" v-if="!token.approved" data-toggle="modal" data-target="#sendTokenToIndividual" class="btn btn-sm btn-success">Approve</button>
                    </td>
                    </tr> 
                </tbody>
                </table>
            </div>
        </div>
    </div>
      
<!-- APPROVE MODAL BOX -->
<div class="modal fade" id="sendTokenToIndividual" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Do you want to approve this token ?</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row justify-content-end">
                <button @click="approveToken"  type="button" class="btn btn-success user m-1">Yes</button>
                <button  type="button" class="btn btn-danger m-1" data-dismiss="modal">No</button>
            </div>
        </div>
        </div>
    </div>
</div>
<!-- END OF APPROVE MODAL BOX -->  
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import toasterMixin from '../../mixins/toasterMixin'
const $ = require('jquery')
export default {
    data() {
        return {
            id:''
        }
    },
    methods: {
        getID(id) {
            this.id = id
        },
        approveToken() {
            this.$store.dispatch('approveToken',this.id )
                .then(response => {
                    if(response) {
                        window.location.reload()
                    }
                }).catch(err => {
                    if(err?.response?.data === undefined) {
                        this.toast({
                            message:'Token approved',
                            type:'error'
                        })
                    }
                })
        }
    },
    computed:{
        ...mapGetters(['allTokens'])
    },
    created() {
        this.$store.dispatch('fetchAllTokens').then( response => setTimeout(function(){$('#dataTable').DataTable();}, 5))
    },
    filters: {
         moment: function (date) {
        return moment(date).format('MMMM Do YYYY');
    }
    },
}
</script>

<style>

</style>