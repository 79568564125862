<template>
    <div class="row text-content">
        <div class="col-12" v-for='(critique,key) in critique' :key="key">
            <div class="row">
                <div class="col-6">
                    <p><strong>Critique</strong> {{critique.text}}</p>
                    <div class="d-flex">
                        <a class="p-2" v-if="critique.pdf" :href="`${url()}${critique.pdf}`" target="_blank" download>pdf</a>
                        <a  class="p-2" v-if="critique.doc" :href="`${url()}${critique.doc}`" target="_blank" download>document</a>
                        <a  class="p-2"  v-if="critique.pic" :href="`${url()}${critique.pic}`" target="_blank" download>image</a>
                        <a  class="p-2" v-if="critique.video" :href="`${critique.video}`" target="_blank" >Youtube</a>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p><small>created at:</small> <small>{{critique.created_at | moment}}</small></p> 
                     <p><small>created by:</small> <small>{{critique.created_by.first_name}} {{critique.created_by.last_name}}</small></p> 
                        </div>
                    <button v-if="!critique.approved" @click="approve(critique.id)" class="btn btn-success btn-sm smallBtn mr-1">
                        Approve
                    </button>
                    <button v-if="critique.approved" @click="disapprove(critique.id)" class="btn btn-info btn-sm smallBtn mr-1">
                        Disapprove
                    </button>
                    
                    <button   class="btn btn-danger btn-sm smallBtn mr-2" data-toggle="modal" data-target="#exampleModal">
                        Delete
                    </button>
                    
                    <button  @click="disapprove(critique.id)" class="btn btn-warning btn-sm smallBtn mr-1">
                        Update
                    </button>
                </div>
                </div>
                <div class="col-6" v-if="critique.synthesis && critique.synthesis.length > 0">
                     <Resolution :resolution="critique.synthesis" />
                </div>

                <!-- Modal -->
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Delete Critique</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure you want to do this ?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger" @click="deleteCritique(critique.id)">Confirm</button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <hr>
        
        </div>
    </div>

</template>

<script>
import toasterMixin from '../../mixins/toasterMixin'
import Resolution from './Resolution'
import moment from 'moment'
import url from '../../link'
export default {
    name:'critique',
    props: {
        critique:Array
    },
    components: {
        Resolution
    },
     filters: {
            moment: function (date) {
                return moment(date).format('MMMM Do YYYY');
            }
            },
    methods: {
        url() {
            return url()
        },
        approve(id) {
          const data = {
              id:id,
              approve: {
                   "approved": true
              }
          }
        
          this.$store.dispatch('approveOrDisapproveAntiThesis',data)
            .then(response => {
                 window.location.reload()
                this.toast({
                    message:'thesis approved.',
                    type:'success'
                })
               
            }).catch(err => {
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
                if(err.response.status === 401) {
                    this.toast({
                    message:err.response.data.message,
                    type:'error'
                })
                }
            })
      },
      disapprove(id) {
          const data = {
              id:id,
              approve: {
                   "approved": false
              }
          }
          this.$store.dispatch('approveOrDisapproveAntiThesis',data)
            .then(response => {
                this.toast({
                    message:'thesis disapproved.',
                    type:'success'
                })
                 window.location.reload()
            }).catch(err => {
                
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
            })
      },
      deleteCritique(id){
          this.$store.dispatch('deleteCritique',id)
          .then(response =>{
              this.toast({
                  message:'critique deleted successfully',
                  type:'sucess'
              }).catch(err =>{
                   if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
              })
          })
      }
    },
    created() {
    //   
    }
}
</script>

<style>

</style>