<template>
         <div>
             <div class="row">
        <div class="col-12 my-3">
            <h2 class="my-3  text-secondary" style="font-size:1.2em; display:inline-block">Debates</h2>
        </div>
      </div>

      <div class="card shadow-sm mb-5">

              <div class="card-header">
                  <h3 class="my-2 text-secondary" style="font-size:1em;">Debates</h3>
              </div>

            <div class="card-body">
            <div class="table-responsive">
                <table
                class="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
                >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Created On</th>
                        <th>Debate Article</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(debate, key) in debateArticles" :key="key">
                        <td>{{debate.id}}</td>
                        <td>
                            {{ debate.created_at | moment }}
                        </td>
                        <td>
                            <div class="card">
                        <div class="card-body">
                            <p>
                                <strong>Topic</strong>   {{debate.topic}}
                            </p>
                            <p>
                                <strong>Body</strong> {{debate.body}}
                            </p>
                                <div class="row">
                                <div class="col-md-12">
                                    <p><small>created By:</small> <small>{{debate.created_by[0].first_name}} {{debate.created_by[0].last_name}}</small></p>
                                    <p><small>email:</small> <small>{{debate.created_by[0].email}}</small></p>
                                </div>
                                    <button v-if="debate.approved" class="btn btn-success mr-2" @click="contributions(debate.id)">View Contributions</button>
                                    <button v-if="!debate.approved" @click="approve(key, debate.id)" class="btn btn-success btn-sm smallBtn mr-1">
                                        Approve
                                </button>
                                    <button v-if="debate.approved" @click="disapprove(key, debate.id)" class="btn btn-info btn-sm smallBtn mr-1">
                                        Disapprove
                                </button>
                                <button  data-toggle="modal" data-target="#exampleModal" class="btn btn-danger btn-sm smallBtn mr-1">
                                        Delete
                                </button>
                                <button class="btn btn-warning btn-sm">
                                    Update
                                </button>
                                </div>
                        </div>
                    </div>
                            <!-- Modal -->
                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Delete Critique</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Are you sure you want to do this ?
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                            <button type="button" class="btn btn-danger" @click="deleteDebate(debate.id)">Confirm</button>
                        </div>
                        </div>
                    </div>
                    </div>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>
             </div>   
        
       <!-- start issue mandate modal content -->
      <!-- end of issue mandate modal content -->

</template>
<script>
import { mapGetters } from 'vuex'
import toasterMixin from '../../mixins/toasterMixin'
import moment from 'moment'
const $ = require('jquery')

export default {
    name:'debate-articles',
    components: {
    },
    data() {
      return {
        id:null,
        success:null,
        addError:null,
        agencyError:null,
        error:null,
        loading:false,
        mandate:null
      }
    },
    methods: {
      contributions(id) {
        this.$router.replace(`/blog/debate-articles/${id}/contributions`)
      },
      approve(key,id) {
          const data = {
              key:key,
              id:id,
              approve: {
                   "approved": true
              }
          }
          this.$store.dispatch('approveOrDisapproveDebateArticle',data)
            .then(response => {
                this.toast({
                    message:'Debate article approved.',
                    type:'success'
                })
            }).catch(err => {
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
            })
      },
      disapprove(key,id) {
          const data = {
              key:key,
              id:id,
              approve: {
                   "approved": false
              }
          }
          this.$store.dispatch('approveOrDisapproveDebateArticle',data)
            .then(response => {
                this.toast({
                    message:'Debate article disapproved.',
                    type:'success'
                })
            }).catch(err => {
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
            })
      },
      fetchDebateArticles() {
          this.$store.dispatch('fetchDbateArtcles')
          .then( response => setTimeout(function(){$('#dataTable').DataTable();}, 5))
            .catch(err => {
              if(err?.response?.data === undefined) {
                this.toast({
                    message:'Network error. Check Network connection and try again.',
                    type:'error'
                })
              }
            })
      },
      deleteDebate(id){
          this.$store.dispatch('deleteDebate',id)
          .then(response =>{
              console.log(response)
              this.toast({
                  message:'debate deleted successfully',
                  type:'success'
              })
              this.fetchDebateArticles() 
          })
          .catch(err =>{
              console.log(err)
          })
      }
    },
    computed: { ...mapGetters(["debateArticles"]) },
    created() {
          this.fetchDebateArticles() 
        },
          filters: {
         moment: function (date) {
        return moment(date).format('MMMM Do YYYY');
    }
    },
}
</script>
<style scoped>
  button, table tr td{
    font-size: .9em;
  }
  .smallBtn{
      font-size: .8em;
      padding-top:5px;
      padding-bottom:5px;
  }
  .table-title{
    padding:10px;
    margin-bottom: 5px;
    background: linear-gradient(40deg,#ff2020, #05ffa3);
    color: #fff;
    border-radius: 1px 1px 0 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title h2{
    font-size: 24px;
}
</style>