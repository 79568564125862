<template>
  <div class="container-fluid">
      <div class="row">
          <button style="float:left" class="my-2 btn btn-danger" @click="back">back</button>
      <div class="col-12" >
          <div class="card">
              <div class="card-body" v-if="challenge.thinkers_critique && challenge.thinkers_critique.length == 0">
                  No Contrbutions Available
              </div>
          </div>
      </div>
      </div>
    <div class="row"  v-if="challenge.thinkers_critique && challenge.thinkers_critique.length > 0">
        <div class="col-12 px-3">
            <div class="row">
                <div class="col-12">
                    <p style="color:red">LEADER'S VISION</p>
                    <p>
                        <strong>Heading</strong>:{{challenge.heading}}
                    </p>
                    <p>
                        <strong>
                            Summary
                        </strong>
                        :{{challenge.summary}}
                    </p>
                    <div class="d-flex">
                        <a class="p-2" v-if="challenge.pdf" :href="`${url()}${challenge.pdf}`" target="_blank" download>pdf</a>
                        <a  class="p-2" v-if="challenge.doc" :href="`${url()}${challenge.doc}`" target="_blank" download>document</a>
                        <a  class="p-2"  v-if="challenge.image" :href="`${url()}${challenge.image}`" target="_blank" download>image</a>
                        <a  class="p-2" v-if="challenge.video" :href="`${challenge.video}`" target="_blank" >Youtube</a>
                    </div>
                    <div class="d-flex justify-content-between">
                         <p><small>created at:</small> <small>{{challenge.created_at | moment}}</small></p>
                         <p><small>created by:</small> <small>{{challenge.created_by[0].first_name}} {{challenge.created_by[0].last_name}}</small></p>
                    </div>
                </div>
            </div>
             <hr>
        </div>
            <div class="col-12 px-3 " v-for="(critique, key) in challenge.thinkers_critique" :key="key">
                <div class="row">
                    <div class="col-6">
                         <div class="row">
                             <div class="col-12" style="border-right:1px solid black">
                                 <p style="color:red">CRITIQUE</p>
                                    <p>
                                        <strong>Heading</strong>:{{critique.heading}}
                                    </p>
                                    <p>
                                        <strong>
                                            Summary
                                        </strong>
                                        :{{critique.summary}}
                                    </p>
                                    <div class="d-flex">
                                        <a class="p-2" v-if="critique.pdf" :href="`${url()}${critique.pdf}`" target="_blank" download>pdf</a>
                                        <a  class="p-2" v-if="critique.doc" :href="`${url()}${critique.doc}`" target="_blank" download>document</a>
                                        <a  class="p-2"  v-if="critique.image" :href="`${url()}${critique.image}`" target="_blank" download>image</a>
                                        <a  class="p-2" v-if="critique.video" :href="`${critique.video}`" target="_blank" >Youtube</a>
                                    </div>
                                      <div class="row">
                                   <div class="col-md-12">
                                        <p><small>created at:</small> <small>{{critique.created_at | moment}}</small></p>
                                    <p><small>created by:</small> <small>{{critique.created_by.first_name}} {{critique.created_by.last_name}}</small></p>
                                   </div>
                                </div>
                                    <button class="btn btn-success btn-sm mr-2" @click="approve(key,critique.id)" v-if="!critique.approved">
                                        Approve
                                    </button>
                                    <button class="btn btn-warning btn-sm mr-2" @click="disapprove(key,critique.id)" v-if="critique.approved">
                                        Disapprove
                                    </button>
                                    <button class="btn btn-info btn-sm mr-2">
                                        Update
                                    </button>
                                    <button class="btn btn-danger btn-sm">
                                        Delete
                                    </button>
        
                             </div>
                         </div>
                    </div>
                    <div class="col-6" v-if="critique.enterpreneurs_plan && critique.enterpreneurs_plan.length > 0">
                        <div class="row" v-for="(plan,key) in critique.enterpreneurs_plan" :key="key">
                            <div class="col-12">
                                         <p style="color:red">Enterpreneur's Plan</p>
                                    <p>
                                        <strong>Heading</strong>:{{plan.heading}}
                                    </p>
                                    <p>
                                        <strong>
                                            Summary
                                        </strong>
                                        :{{plan.summary}}
                                    </p>
                                    <div class="d-flex">
                                        <a class="p-2" v-if="plan.pdf" :href="`${url()}${plan.pdf}`" target="_blank" download>pdf</a>
                                        <a  class="p-2" v-if="plan.doc" :href="`${url()}${plan.doc}`" target="_blank" download>document</a>
                                        <a  class="p-2"  v-if="plan.image" :href="`${url()}${plan.image}`" target="_blank" download>image</a>
                                        <a  class="p-2" v-if="plan.video" :href="`${plan.video}`" target="_blank" >Youtube</a>
                                    </div>
                                      <div class="d-flex justify-content-between">
                                    <p><small>created at:</small> <small>{{plan.created_at | moment}}</small></p>
                                    <p><small>created by:</small> <small>{{plan.created_by.first_name}} {{plan.created_by.last_name}}</small></p>
                                </div>
                                    <button class="btn btn-success btn-sm mr-2" @click="approvePlan(key,plan.id)" v-if="!plan.approved">
                                        Approve
                                    </button>
                                    <button class="btn btn-warning btn-sm mr-2" @click="disapprovePlan(key,plan.id)" v-if="plan.approved">
                                        Disapprove
                                    </button>
                                     <button class="btn btn-info btn-sm mr-2">
                                        Update
                                    </button>
                                    <button class="btn btn-danger btn-sm" @click="deletePlan(plan.id)">
                                        Delete
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import toasterMixin from '../../mixins/toasterMixin'
import moment from 'moment'
import url from '../../link'
export default {
    name:'challenge-contributions',
    data() {
        return {
            challenge:{}
        }
    },
        filters: {
            moment: function (date) {
                return moment(date).format('MMMM Do YYYY');
            }
            },
    methods: {
        url() {
            return url()
        },
        back() {
            this.$router.push('/blog/challenge')
        },
         approve(key,id) {
          const data = {
              key:key,
              id:id,
              approve: {
                   "approved": true
              }
          }
         
          this.$store.dispatch('approveOrDisapproveChallengeCritique',data)
            .then(response => {
                this.toast({
                    message:'Critique approved.',
                    type:'success'
                })
                window.location.reload()
            }).catch(err => {
                console.log(err)
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
            })
      },
      approvePlan(key,id) {
          const data = {
              key:key,
              id:id,
              approve: {
                   "approved": true
              }
          }
          this.$store.dispatch('approveOrDisapproveChallengePlan',data)
            .then(response => {
                this.toast({
                    message:'Plan approved.',
                    type:'success'
                })
                window.location.reload()
            }).catch(err => {
                console.log(err)
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
            })
      },
      disapprove(key,id) {
          const data = {
              key:key,
              id:id,
              approve: {
                   "approved": false
              }
          }
          this.$store.dispatch('approveOrDisapproveChallengeCritique',data)
            .then(response => {
                this.toast({
                    message:'Critque disapproved.',
                    type:'success'
                })
                window.location.reload()
            }).catch(err => {
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
            })
      },
      disapprovePlan(key,id) {
          const data = {
              key:key,
              id:id,
              approve: {
                   "approved": false
              }
          }
          this.$store.dispatch('approveOrDisapproveChallengePlan',data)
            .then(response => {
                this.toast({
                    message:'Plan disapproved.',
                    type:'success'
                })
                window.location.reload()
            }).catch(err => {
                if(err?.response.data == undefined) {
                   this.toast({
                    message:'Network error',
                    type:'error'
                })  
                }
            })
      },
      
      fetchDebateArticles() {
          this.$store.dispatch('fetchDebateLeadersVisions')
            .then(response => {
                this.challenge = response.data.find( debate => debate.id == this.$route.params.challengeID)
                console.log('contributions', this.challenge)
            })
            .catch(err => {
              if(err?.response?.data === undefined) {
                this.toast({
                    message:'Network error. Check Network connection and try again.',
                    type:'error'
                })
              }
            })
      }
    },
    
    mounted() {
       this.fetchDebateArticles() 
    },
}
</script>

<style scoped>
    .text-content{
        border:1px solid red
    }
    h2{
        font-size: 1em;
    }
</style>